import React, { useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import { RiCloseLine, RiMenu3Line } from "react-icons/ri";
import Menu from "./Menu";
import "./navbar.css";
import logo from "../../assets/logo_dark.png";

const Home = () => (
  <p>
    <Link to="/">Home</Link>
  </p>
);

const Navbar = ({ home }) => {
  const ref = createRef();
  const [toggleMenu, setToggleMenu] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setToggleMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div className="navbar">
      <div className="navbar-links">
        <a href="/" className="navbar-links_logo">
          <img src={logo} alt="logo" />
        </a>
        <div className="navbar-links_container">
          {home ? <Home /> : <Menu />}
        </div>
      </div>
      <div className="navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#2D2C2E"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#2D2C2E"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center" ref={ref}>
            <div className="navbar-menu_container-links">
              {home ? <Home /> : <Menu />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
