import React from "react";
import {
  FaChildDress,
  FaBabyCarriage,
  FaMagnifyingGlass,
  FaPalette,
} from "react-icons/fa6";
import { LuBaby } from "react-icons/lu";
import {
  FaRegStar,
  FaUserGraduate,
  FaTruckMoving,
  FaCode,
  FaPaintBrush,
} from "react-icons/fa"; // Example icon, change as needed
import "./whatIDo.css"; // Importing the CSS file

import { SectionTitle } from "../../components";

const MyStory = () => {
  const events = [
    {
      date: "1989",
      description: "Urodziłam się we Wrocławiu.",
      icon: <LuBaby />,
    },
    {
      date: "2008",
      description: (
        <>
          <p>Ukończyłam LO IX we Wrocławiu.</p>
          <p>
            Rozpoczęłam studia na Wydziale Elektrycznym Politechniki
            Wrocławskiej.
          </p>
        </>
      ),
      icon: <FaChildDress />,
    },
    {
      date: "2013",
      description: (
        <>
          <p>Ukończyłam studia z dyplomem magistra inżyniera.</p>
          <p>Rozpoczęłam pracę jako inżynier w automatyce przemysłowej.</p>
        </>
      ),
      icon: <FaUserGraduate />,
    },
    {
      date: "2015",
      description: "Przeprowadziłam się do Niemiec.",
      icon: <FaTruckMoving />,
    },
    {
      date: "2018",
      description:
        "Przekwalifikowałam się z inżyniera na web developera - pierwsze kroki w web designie.",
      icon: <FaCode />,
    },
    {
      date: "2020",
      description: (
        <>
          <p>Zostałam mamą po raz pierwszy.</p>
          <p>
            Zdefiniowałam moje <b>wartości</b>: rodzina, zdrowie, rozwój,
            niezależność.
          </p>
        </>
      ),
      icon: <FaBabyCarriage />,
    },
    {
      date: "2021",
      description: (
        <>
          <p>
            Odkryłam pasję do rysowania cyfrowego oraz malowania akwarelą i
            gwaszem.
          </p>
          <p>Wyszłam za mąż.</p>
        </>
      ),
      icon: <FaPalette />,
    },
    {
      date: "2022",
      description: (
        <>
          <p>Zostałam artystką graficzką.</p>
          <p>Zaczęłam uczyć się fundamentalnych zasad grafiki i dzielić się tą wiedzą na social mediach.</p>
        </>
      ),
      icon: <FaPaintBrush />,
    },
    {
      date: "2023",
      description: (
        <>
          <p>Zostałam mamą po raz drugi.</p>
          <p>Wróciłam do Polski.</p>
          <p>Wciąż rowijałam swoje umiejętności artystyczne.</p>
        </>
      ),
      icon: <FaBabyCarriage />,
    },
    {
      date: "2024",
      description: (
        <>
          <p>
            Odkryłam piękno trójwymarowego świata <b>pop-up</b> w książkach.
          </p>
          <p> Zaczęłam uczyć się inynierii papieru i poprowadziłam pierwsze warsztaty z tworzenia kartek pop-up.</p>
        </>
      ),
      icon: <FaMagnifyingGlass />,
    },
    {
      date: "2025",
      description: (
        <>
          <p>
            Oferuję warsztaty plastyczne dla dzieci i dorosłych.
          </p>
          <p> Pracuję nad nowymi projektami. Chciałabym stworzyć własną książkę z elementami pop-up.</p>
        </>
      ),
      icon: <FaRegStar />,
    },
  ];

  return (
    <div className="about section__padding" id="about">
      <SectionTitle title="Moja historia" />
      <div className="story-container">
        {events.map((event, index) => (
          <div key={index} className="story-event">
            <div className="story-icon">{event.icon}</div>
            <h3>{event.date}</h3>
            <p>{event.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyStory;
