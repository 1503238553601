import React from "react";
import "./header.css";
import Main from "./Main";

// Have a modern website with original design
// Get unique digital art

const Header = () => {
  return (
    <div className="header" id="home">
      <Main />
    </div>
  );
};

export default Header;
