import React from "react";

const Menu = ({ contact = true }) => {
  return (
    <>
      <p>
        <a href="/">Strona główna</a>
      </p>
      <p>
        <a href="/#services">Warsztaty</a>
      </p>
      <p>
        <a href="/#features">Dodatki</a>
      </p>
      <p>
        <a href="portfolio">Portfolio</a>
      </p>
      {/* <p>
        <a href="/#dmb">DesignMagicBox</a>
      </p> */}
      <p>
        <a href="/#about">Moja historia</a>
      </p>
      {contact ? (
        <p>
          <a href="#contact">
            Kontakt
          </a>
        </p>
      ) : null}
    </>
  );
};

export default Menu;
