import React from "react";
import CTAPhone from "./CTAPhone";
import CTA from "./CTA";

const CTABlock = () => {
  return (
    <div className="cta-block">
      <span className="cta-gap">Zadzwoń</span>
      <CTAPhone />
      <span className="cta-gap">lub napisz</span>
      <CTA />
    </div>
  );
};

export default CTABlock;
