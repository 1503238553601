import React from "react";
import Design from "../../assets/graphic2.png";
import Timer from "../../assets/timer.png";
import Const from "../../assets/const.png";
import Underwater from "../../assets/underwater.webp";
import "./header.css";

// Have a modern website with original design
// Get unique digital art

const Main = () => {
  return (
    <div className="main section__padding" id="home">
      <div className="title serif">
        <p>Warsztaty plastyczne</p>
        <p className="serif gradient__text pop">
          <b>POP-UP</b>
        </p>
        <div className="subtitle">
          <p>
            Odkryj magiczny papierowy świat 3D, pełen "wyskakujących" elementów
            z kartek oraz plansz do zabawy i nauki.
          </p>
          <p><a href="/#services">
            <button className="serif">Zapoznaj się z ofertą</button>
          </a></p>
        </div>
      </div>
      <div>
        <img src={Underwater} />
      </div>
    </div>
  );
};

export default Main;
