import React from "react";
import { CTABlock, SectionTitle } from "../../components";
import "./contact.css";

const Contact = () => {
  return (
    <div className="contact section__padding" id="contact">
      <SectionTitle title="Jesteś zainteresowany ofertą lub masz pytania?" />
      <CTABlock />
    </div>
  );
};

export default Contact;
