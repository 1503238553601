import React from "react";
import "./forClients.css";
import { CTA, CTAPhone, SectionTitle } from "../../components";

// Import the images
import WS1 from "../../assets/workshopsImages/2.png";
import WS2 from "../../assets/workshopsImages/3.png";
import WS3 from "../../assets/workshopsImages/4.png";
import Preschool1 from "../../assets/preschool_1.png";
import Preschool2 from "../../assets/preschool_2.png";
import Preschool3 from "../../assets/preschool_3.png";
import Adult1 from "../../assets/adult_1.png";
import Adult2 from "../../assets/adult_2.png";
import Adult3 from "../../assets/adult_3.png";
import Adult4 from "../../assets/adult_4.png";
import SzczesliweLata from "../../assets/loo_szczesliwe_lata.png";
import GCKZoraawina from "../../assets/logo_gck_zorawina.png";
import individualsImg from "../../assets/Individuals.jpg";
import marketersImg from "../../assets/Marketers.jpg";
import interiorsImg from "../../assets/Interiors.jpg";
import CTABlock from "../../components/cta/CTABlock";

// Define the ForClients component

const MyWorkshops = ({ places }) => (
  <div className="places-box">
    <h2>Tutaj prowadziłam warsztaty:</h2>
    <div className="places">
      {places.map((place) => (
        <img src={place} />
      ))}
    </div>
  </div>
);

const ForClients = () => {
  const preschools = [SzczesliweLata];
  const adults = [GCKZoraawina];
  return (
    <div className="for-clients section__padding" id="services">
      <div className="preschool container">
        <h1 className="serif">Warsztaty dla przedszkoli</h1>
        <div className="details">
          <img src={WS1} />
          <img src={Preschool1} />
        </div>
        <div className="details">
          <img src={Preschool2} />
          <img src={WS2} />
        </div>
        <div className="details">
          <img src={WS3} />
          <img src={Preschool3} />
        </div>
        <CTABlock />
        <MyWorkshops places={preschools} />
      </div>
      <div className="adult container">
        <h1 className="serif">Warsztaty dla dorosłych</h1>
        <div className="details">
          <img src={Adult1} />
          <img src={Adult2} />
        </div>
        <div className="details">
          <img src={Adult3} />
          <img src={Adult4} />
        </div>
        <CTABlock />
        <MyWorkshops places={adults} />
      </div>
    </div>
  );
};

export default ForClients;
